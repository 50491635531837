import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useMemo
} from 'react';
import ReactGA from 'react-ga';
import Axios from 'axios';
import Header from './components/Header';
import Description from './components/Description';
import Rate from './components/Rate';
import Platforms from './components/Platforms';
import General from './components/General';
import ContactForm from './components/ContactForm';
import Layout from './components/Layout';
import Details from './components/Details';
import Estimate from './components/Estimate';
import config from './config/schema';
import { calculate, extract } from './calculator';
import {
  HowBigAppLevel,
  PlatformsLevel,
  QualityLevel,
  UILevel
} from './config/configEstimator';
import PersonalEstimate from './components/PersonalEstimate';
import Footer from './components/Footer/Footer';
import useSocket from './socket';
import useAnalytics from './useAnalytics';
import BuildEstimate from './components/BuildEstimate';
import Faq from './components/Faq';

function App() {
  const [state, setState] = useState(config);
  const [stateHowBigAppLevel, setHowBigAppLevel] = useState(HowBigAppLevel);
  const [stateUILevel, setUILevel] = useState(UILevel);
  const [stateQualityLevel, setQualityLevel] = useState(QualityLevel);
  const [statePlatformsLevel, setPlatformsLevel] = useState(PlatformsLevel);
  const [AIQuestions, setAIQuestions] = useState(state.ai);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoadingEstimate, setIsLoadingEstimate] = useState(false);
  const refHowBig = useRef();
  const refUILevel = useRef();
  const refQualityLevel = useRef();
  const refPlatformsLevel = useRef();

  const {
    value,
    sendSocketData,
    setValue,
    addFeature,
    isGenerationCompleted,
    isFeatureGenerationCompleted,
    clearGenerationStatus,
    isFetching
  } = useSocket();

  const onSaveHistory = useCallback(() => {
    clearGenerationStatus();
  }, [clearGenerationStatus]);

  const { getSelectedOption, recordHistory } = useAnalytics({
    onSave: onSaveHistory
  });

  useEffect(() => {
    if (window.location) {
      ReactGA.initialize('UA-175681468-1');
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, []);

  useEffect(() => {
    if (value !== null && value?.length !== 0) {
      setIsLoadingEstimate(false);
    }
  }, [value]);

  /** Start history logic **/

  useEffect(() => {
    recordHistory(
      'input',
      'AIQuestions',
      AIQuestions.filter(item => item.text).map(item => ({
        title: item.title,
        text: item.text
      })),
      true
    );
  }, [AIQuestions, recordHistory]);

  useEffect(() => {
    recordHistory(
      'input',
      'AppSizeLevel',
      getSelectedOption(stateHowBigAppLevel)
    );
  }, [stateHowBigAppLevel, recordHistory, getSelectedOption]);

  useEffect(() => {
    recordHistory('input', 'UILevel', getSelectedOption(stateUILevel));
  }, [stateUILevel, recordHistory, getSelectedOption]);

  useEffect(() => {
    recordHistory(
      'input',
      'QualityLevel',
      getSelectedOption(stateQualityLevel)
    );
  }, [stateQualityLevel, recordHistory, getSelectedOption]);

  useEffect(() => {
    recordHistory(
      'input',
      'PlatformsLevel',
      getSelectedOption(statePlatformsLevel)
    );
  }, [statePlatformsLevel, recordHistory, getSelectedOption]);

  useEffect(() => {
    recordHistory('input', 'rate', state.rate);
  }, [state.rate, recordHistory]);

  useEffect(() => {
    if (value && value.length && isGenerationCompleted) {
      recordHistory('result', 'estimate', estimateData);
    }
  }, [value, isGenerationCompleted, isFeatureGenerationCompleted]);

  /** End history logic */

  const sendMessage = useCallback(
    contactData => {
      try {
        ReactGA.event({
          category: 'Send',
          action: 'mail'
        });
        Axios.post('/api/contact', { contactData, features: extract(state) })
          .then(() => setState(state => ({ ...state, formSuccess: true })))
          .catch(() => setState(state => ({ ...state, formError: true })));
      } catch (error) {
        ReactGA.exception({
          description: error,
          fatal: true
        });
        setState(state => ({ ...state, formError: true }));
      }
    },
    [state]
  );

  const clearForm = useCallback(() => {
    setState(s =>
      s.formSuccess === true ? JSON.parse(JSON.stringify(config)) : calculate(s)
    );
  }, []);

  const handlePlatformSelect = useCallback(
    (index, selected, setState, isPlatform) => {
      setValue(null);
      setState(s => {
        let arr = s.map((x, i) => {
          if (isPlatform) {
            if (index === i) {
              return { ...x, selected: selected };
            } else {
              return x;
            }
          } else {
            if (index === i) {
              return { ...x, selected: true };
            }
          }
          return { ...x, selected: false };
        });

        if (isPlatform) {
          if (statePlatformsLevel[index].icon === 'hybrid' && selected) {
            arr = arr.map(x =>
              x.icon === 'ios' || x.icon === 'android'
                ? { ...x, selected: false }
                : x
            );
          }
          if (
            (statePlatformsLevel[index].icon === 'ios' ||
              statePlatformsLevel[index].icon === 'android') &&
            selected
          ) {
            arr = arr.map(x =>
              x.icon === 'hybrid' ? { ...x, selected: false } : x
            );
          }
        }

        return arr;
      });
    },
    [setValue, statePlatformsLevel]
  );

  const handleDeleteFeature = useCallback(
    index => {
      setValue(data => data.filter((x, i) => i !== index));
    },
    [setValue]
  );

  const handleAddFeature = useCallback(
    async (featureName, featureDescription, roleName) => {
      const payload = {
        domain: AIQuestions[1].text,
        competitor_name: AIQuestions[2].text,
        product_version: stateHowBigAppLevel.find(x => x.selected)?.title || '',
        add_feature_name: featureName,
        add_feature_descr: featureDescription,
        add_feature_roles: roleName,
        add_feature_platforms: statePlatformsLevel.filter(x => x.selected),
        idea: AIQuestions[0].text
      };
      addFeature(payload);
      recordHistory('input', 'addFeature', payload);
    },
    [AIQuestions, addFeature, stateHowBigAppLevel, statePlatformsLevel]
  );

  const handleSingleSelect = useCallback(
    (index, subindex, selected) => {
      setValue(null);
      setState(s => {
        for (let j = 0; j < s.general[index].variants.length; j++) {
          s.general[index].variants[j].selected =
            j === subindex ? selected : false;
          ReactGA.event({
            category: s.general[index].title,
            action: s.general[index].variants[j].title,
            label: `${s.general[index].variants[j].selected}`
          });
        }
        return calculate(s);
      });
    },
    [setValue]
  );

  const handleMultiSelect = useCallback(
    (index, subindex, selected, type) => {
      setValue(null);
      setState(s => {
        s[type][index].variants[subindex].selected = selected;
        ReactGA.event({
          category: s[type][index].title,
          action: s[type][index].variants[subindex].title,
          label: `${selected}`
        });
        return calculate(s);
      });
    },
    [setValue]
  );

  const handleChangeRate = useCallback(rate => {
    setState(s => {
      s.rate = rate;
      ReactGA.event({
        category: 'Rate',
        action: 'Change',
        label: `${rate}`
      });
      return calculate(s);
    });
  }, []);

  const handleOpenModal = action => {
    setIsModalOpen(action);
  };

  const handleOnScroll = () => {
    const getOffsetHeightToBody = element => {
      let totalOffset = 0;
      let currentElement = element;

      while (currentElement) {
        totalOffset += currentElement.offsetTop;
        currentElement = currentElement.offsetParent;
      }

      return totalOffset;
    };

    const scrollToElement = element => {
      if (element) {
        window.scrollTo({
          top: getOffsetHeightToBody(element),
          behavior: 'smooth'
        });
      }
    };

    const notValidAIQuestion = AIQuestions.find(q => !q.text);
    if (notValidAIQuestion?.title) {
      const targetElement = document.getElementsByName(
        notValidAIQuestion.title
      )[0];
      scrollToElement(targetElement);
      return;
    }

    const unselectedStates = [
      { state: stateHowBigAppLevel, ref: refHowBig },
      { state: stateUILevel, ref: refUILevel },
      { state: stateQualityLevel, ref: refQualityLevel },
      { state: statePlatformsLevel, ref: refPlatformsLevel }
    ];

    for (const { state, ref } of unselectedStates) {
      if (!state.find(x => x.selected)?.selected) {
        scrollToElement(ref?.current);
        return;
      }
    }
  };

  const handleGetEstimate = useCallback(async () => {
    setIsLoadingEstimate(true);
    let additional_feature = '';
    statePlatformsLevel.forEach(x => {
      if (x.selected) {
        additional_feature = additional_feature + ' ' + x.title;
      }
    });

    sendSocketData({
      domain: AIQuestions[1].text,
      competitor_name: AIQuestions[2].text,
      product_version: stateHowBigAppLevel.find(x => x.selected)?.title || '',
      features_num:
        stateHowBigAppLevel.find(x => x.selected)?.features_num || '',
      roles: AIQuestions[3].text,
      sum_hours: state.rate,
      min_total_hours: '2400',
      additional_feature: additional_feature,
      typeQA: stateQualityLevel.find(x => x.selected)?.title || '',
      add_feature_platforms: statePlatformsLevel.filter(x => x.selected),
      project_idea: AIQuestions[0].text
    });
  }, [
    AIQuestions,
    sendSocketData,
    state,
    stateHowBigAppLevel,
    statePlatformsLevel,
    stateQualityLevel
  ]);

  const isDisable = useMemo(() => {
    const hasValidAIQuestions =
      AIQuestions?.length >= 4 && AIQuestions.every(q => q.text);

    const hasValidSelections = [
      stateHowBigAppLevel,
      stateUILevel,
      stateQualityLevel,
      statePlatformsLevel
    ].every(state => state.some(x => x.selected));

    return !(state.rate && hasValidAIQuestions && hasValidSelections);
  }, [
    AIQuestions,
    state.rate,
    stateHowBigAppLevel,
    stateUILevel,
    stateQualityLevel,
    statePlatformsLevel
  ]);

  const estimateData = useMemo(() => {
    const updatedEstimates = value?.filter((x, i) => {
      // check if some section is broken; value starts with ** and ends with **
      const isBrokenSection = Object.keys(x).some(key => {
        if (typeof x[key] === 'string') {
          return x[key].startsWith('**') && x[key].endsWith('**');
        }
        return false;
      });
      return !isBrokenSection;
    });

    return updatedEstimates;
    //  || [{
    //   'Feature Name': 'Feature Name',
    //   'Acceptance Criteria': 'Acceptance Criteria',
    //   '3rd Party Services': '3rd Party Services',
    //   'User Story': 'User Story',
    //   Backend: '-',
    //   Frontend: '-',
    //   Android: '-',
    //   iOS: '-',
    //   'React Native': '-',
    //   'UI/UX': '-',
    //   'QA': '-',
    //   'BA/PM': '-'
    // },
    // {
    //   'Feature Name': 'Feature Name',
    //   'Acceptance Criteria': 'Acceptance Criteria',
    //   '3rd Party Services': '3rd Party Services',
    //   'User Story': 'User Story',
    //   Backend: '2',
    //   Frontend: '1',
    //   Android: '0',
    //   iOS: '0',
    //   'React Native': '0',
    //   'UI/UX': '3',
    //   'QA': '12',
    //   'BA/PM': '13'
    // }];
  }, [value]);

  return (
    <>
      <Header />
      <Description />
      <Rate
        onChange={handleChangeRate}
        rate={state.rate}
        AIQuestions={AIQuestions}
        setAIQuestions={setAIQuestions}
        setValue={setValue}
        isFetching={isFetching}
      />
      <div ref={refHowBig}>
        <Platforms
          title={'How big is your app?'}
          platforms={stateHowBigAppLevel}
          onChange={(index, selected) =>
            handlePlatformSelect(
              index,
              selected,
              setHowBigAppLevel,
              false,
              'stateHowBigAppLevel'
            )
          }
          isFetching={isFetching}
          index={3}
          required
        />
      </div>
      <div ref={refUILevel}>
        <Platforms
          title={'What level of UI would you like?'}
          platforms={stateUILevel}
          onChange={(index, selected) =>
            handlePlatformSelect(index, selected, setUILevel, false, 'uiLevel')
          }
          isFetching={isFetching}
          index={4}
          required
        />
      </div>
      <div ref={refQualityLevel}>
        <Platforms
          title={'What level of quality assurance would you like?'}
          platforms={stateQualityLevel}
          onChange={(index, selected) =>
            handlePlatformSelect(
              index,
              selected,
              setQualityLevel,
              false,
              'qualityLevel'
            )
          }
          isFetching={isFetching}
          index={5}
          required
        />
      </div>
      <div ref={refPlatformsLevel}>
        <Platforms
          title={'What platforms do you need?'}
          platforms={statePlatformsLevel}
          onChange={(index, selected) =>
            handlePlatformSelect(
              index,
              selected,
              setPlatformsLevel,
              true,
              'platformsLevel'
            )
          }
          isFetching={isFetching}
          index={6}
          required
        />
      </div>
      <PersonalEstimate
        handleGetEstimate={handleGetEstimate}
        handleOnScroll={handleOnScroll}
        estimateData={estimateData}
        handleDeleteFeature={handleDeleteFeature}
        handleAddFeature={handleAddFeature}
        isLoadingEstimate={isLoadingEstimate}
        rate={state.rate}
        statePlatformsLevel={statePlatformsLevel}
        stateQualityLevel={stateQualityLevel}
        stateUILevel={stateUILevel}
        isDisable={isDisable}
        stateHowBigAppLevel={stateHowBigAppLevel}
        AIQuestions={AIQuestions}
        isFetching={isFetching}
      />
      {state.platforms.some(p => p.selected) && (
        <>
          <Layout
            sidebar={
              <Estimate state={state} handleOpenModal={handleOpenModal} />
            }
          >
            <General general={state.general} onChange={handleSingleSelect} />
            <Details
              platforms={state.platforms}
              details={state.options}
              startIndex={state.general.length + 2}
              onChange={handleMultiSelect}
              type="options"
            />
            <Details
              platforms={state.platforms}
              details={state.specific}
              startIndex={state.general.length + state.options.length + 2}
              onChange={handleMultiSelect}
              type="specific"
            />
          </Layout>
          <ContactForm
            isModalOpen={isModalOpen}
            handleOpenModal={handleOpenModal}
            sendMessage={sendMessage}
            formSuccess={state.formSuccess}
            formError={state.formError}
            clearForm={clearForm}
          />
        </>
      )}
      <BuildEstimate />
      <Faq />
      {/*<CalculatorBlock />*/}
      {/*<Location />*/}
      <Footer />
    </>
  );
}

export default App;
