export const HowBigAppLevel = [
  {
    icon: '5-7features',
    title: 'POC',
    note:
      'Your app probably has around 10+ features screens (excluding any static content, sign in, sign up etc.)',
    selected: false,
    features_num: 5
  },
  {
    icon: '10-12features',
    title: 'MVP',
    note:
      'Your app probably has around 20+ features screens (excluding any static content, sign in, sign up etc.)',
    selected: false,
    features_num: 12
  },
  {
    icon: '20-25features',
    title: 'Product',
    note:
      'Your app probably has around 30+ features screens (excluding any static content, sign in, sign up etc.)',
    selected: false,
    features_num: 25
  }
];
export const UILevel = [
  {
    icon: 'mvp',
    title: 'MVP',
    note:
      'Design based on UI kit with minimal branding, able to fulfill key operations.',
    selected: false
  },
  {
    icon: 'basic',
    title: 'Basic',
    note: 'Partial use of UI kit + customized design.',
    selected: false
  },
  {
    icon: 'product',
    title: 'Polished',
    note: `Fully customized design that doesn't/almost doesn't use ready-made solutions.`,
    selected: false
  }
];
export const QualityLevel = [
  {
    icon: 'mvp',
    title: 'Basic',
    note:
      'Including functional testing, affected area regression, portability for 1 resolution and device, high level test cases and test summary report.',
    selected: false
  },
  {
    icon: 'basic',
    title: 'Standard',
    note:
      'Including functional testing, affected area regression, portability for 1-2 resolutions and devices, API and DB testing.',
    selected: false
  },
  {
    icon: 'product',
    title: 'Product',
    note:
      'Including functional, full regression , portability, non-functional testing (security, performance, and mobile corner cases), API and DB testing, usability, interoperability, etc.',
    selected: false
  }
];
export const PlatformsLevel = [
  {
    icon: 'mvp',
    title: 'Web',
    note: 'A web app.',
    selected: false
  },
  {
    icon: 'ios',
    title: 'iOS',
    note: 'Partial use of UI kit + customized design.',
    selected: false
  },
  {
    icon: 'android',
    title: 'Android',
    note: `Fully customized design that doesn't/almost doesn't use ready-made solutions.`,
    selected: false
  },
  {
    icon: 'hybrid',
    title: 'Hybrid mobile',
    note: `Fully customized design that doesn't/almost doesn't use ready-made solutions.`,
    selected: false
  }
];
