import React, { useCallback, useState } from 'react';
import BlockTitle from '../BlockTitle';
import Image from '../Image';
import './Rate.scss';
import AIQuestionsCard from '../AIQuestionsCard';
const regexp = new RegExp('^[0-9]');

const roundRate = (value, operand) => {
  if (value % 10 > 0) {
    return operand === 'increment'
      ? Math.ceil(value / 10) * 10
      : Math.floor(value / 10) * 10;
  }

  if (operand === 'increment') return value + 10;
  if (operand === 'decrement') return value - 10;
};

const Rate = ({
  onChange,
  rate,
  setAIQuestions,
  AIQuestions,
  setValue,
  isFetching
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const onChangePlatform = useCallback(
    (index, text, selectedMultipleOptions) => {
      setValue(null);
      let optionsIndex;
      setAIQuestions(state =>
        state.map((x, i) => {
          let newItem = x;
          if (i === index) {
            newItem.text = text;

            if (index === 1) {
              optionsIndex = newItem.options.indexOf(newItem.text);
            }
            if (index === 3) {
              newItem.selectedMultipleOptions = selectedMultipleOptions || [];
            }
          }

          if (i === 3 && index === 1) {
            const options = newItem.options ? newItem.options : [];
            const newOptions = newItem.derivedOptions[optionsIndex];
            if (JSON.stringify(options) !== JSON.stringify(newOptions)) {
              newItem.options = newItem.derivedOptions[optionsIndex];
              newItem.text = '';
              newItem.selectedMultipleOptions = [];
            }
          }
          return newItem;
        })
      );
    },
    [setAIQuestions, setValue]
  );

  const handleChange = useCallback(
    value => {
      setValue(null);
      let newRate = value;
      try {
        newRate = Number(newRate);
        if (!regexp.test(`${newRate}`) || newRate < 0) {
          newRate = 0;
        }
        if (newRate > 1000) {
          newRate = 1000;
        }
        onChange(newRate);
      } catch (e) {}
    },
    [onChange, setValue]
  );

  return (
    <div className="rate-container">
      <BlockTitle
        index={1}
        title="What is your current or expected average hourly rate in USD per one team member?"
      />
      <div className="rate">
        <label htmlFor="rate">
          Define average hourly rate of your development team. It can be average
          hourly cost of your inhouse team member or rates that you are paying
          to your technology partner.
        </label>
        <div className="input-container">
          <button
            onMouseEnter={() => {
              setShowTooltip(rate <= 30);
            }}
            onMouseLeave={() => {
              setShowTooltip(false);
            }}
            className={`input-change`}
            onClick={() => {
              if (rate > 30) {
                handleChange(roundRate(rate, 'decrement'));
              }
            }}
            disabled={isFetching}
          >
            -
            {showTooltip && (
              <div className="tooltip">
                Minimum rate is 30. Cannot decrease further.
              </div>
            )}
          </button>
          <div className="input-wrapper">
            <input
              id="rate"
              onChange={e => handleChange(e.target.value)}
              onBlur={e =>
                e.target.value < 30
                  ? handleChange(30)
                  : handleChange(e.target.value)
              }
              type="text"
              min="0.00"
              max="1000.00"
              step="10.00"
              value={`${rate}`}
            />
            <span>$/hour</span>
          </div>
          <button
            className="input-change"
            onClick={() => handleChange(roundRate(rate, 'increment'))}
            disabled={isFetching}
          >
            +
          </button>
        </div>
      </div>
      <div className="AI_questions">
        <BlockTitle index={2} title="AI questions" isAI />
        <div className="platforms platforms-wrap">
          {AIQuestions.map((v, i) => (
            <AIQuestionsCard
              {...v}
              key={i}
              index={i}
              onChangeText={onChangePlatform}
              options={v.options}
              isMultiple={i === 3}
              isFetching={isFetching}
            />
          ))}
        </div>
      </div>
      <Image src="/images/shadow-coin" />
    </div>
  );
};

export default Rate;
