import React, { useCallback, useMemo, useState } from 'react';
import {
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  Input,
  Modal
} from '@material-ui/core';

import './PersonalEstimate.scss';
import BlockTitle from '../BlockTitle';
import Image from '../Image';
import MultiSelectQuestion from '../MultiSelectQuestion';
import { sendEmailEstimate } from '../../api/sendEmailEstimate';

const title = 'Get your personal estimate';
//const desc =
//  'Design based on UI kit with minimal branding, able to fulfill key operations.';
const button = 'Get your estimate';

const isNumber = number => {
  if (!isNaN(parseInt(number))) {
    return number;
  }
  return 0;
};

const PersonalEstimate = ({
  handleGetEstimate,
  handleOnScroll,
  estimateData,
  handleDeleteFeature,
  handleAddFeature,
  isLoadingEstimate,
  rate,
  statePlatformsLevel,
  stateQualityLevel,
  stateUILevel,
  stateHowBigAppLevel,
  isDisable,
  AIQuestions,
  isFetching
}) => {
  const [isSubmited, setIsSubmited] = useState(false);
  const [checked, setChecked] = useState(false);
  const [isModalFeature, setIsModalFeature] = useState(false);
  const [isModalEstimate, setIsModalEstimate] = useState(false);
  const [isBlur, setIsBlur] = useState(true);
  const [featureName, setFeatureName] = useState('');
  const [featureDescription, setFeatureDescription] = useState('');
  const [roleName, setRoleName] = useState('');
  const [email, setEmail] = useState('');
  const [userName, setUserName] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [emailError, setEmailError] = useState(null);

  const handleSetText = useCallback((event, setStateFunc) => {
    setStateFunc(event.target.value);
  }, []);

  const validateEmail = email => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(email);
    setIsEmailValid(isValid);
    return isValid;
  };

  const handleEmailChange = event => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    validateEmail(newEmail);
  };

  const isWeb = useMemo(() => {
    return Boolean(
      statePlatformsLevel.find(x => x.selected && x.title === 'Web')
    );
  }, [statePlatformsLevel]);

  const isAndroid = useMemo(() => {
    return Boolean(
      statePlatformsLevel.find(x => x.selected && x.title === 'Android')
    );
  }, [statePlatformsLevel]);

  const isIOS = useMemo(() => {
    return Boolean(
      statePlatformsLevel.find(x => x.selected && x.title === 'iOS')
    );
  }, [statePlatformsLevel]);

  const isHybrid = useMemo(() => {
    return Boolean(
      statePlatformsLevel.find(x => x.selected && x.title === 'Hybrid mobile')
    );
  }, [statePlatformsLevel]);

  const total = useMemo(() => {
    if (estimateData && estimateData?.length) {
      let data = {
        totalHours: 0,
        backend: 0,
        frontend: 0,
        android: 0,
        iOS: 0,
        react_native: 0,
        ux_ux: 0,
        q_a: 0,
        ba_pa: 0
      };
      estimateData.map(x => {
        data.totalHours =
          data.totalHours +
          isNumber(parseInt(x.Backend)) +
          (isWeb ? isNumber(parseInt(x.Frontend)) : 0) +
          (isAndroid ? isNumber(parseInt(x.Android)) : 0) +
          (isIOS ? isNumber(parseInt(x.iOS)) : 0) +
          (isHybrid ? isNumber(parseInt(x['React Native'])) : 0) +
          isNumber(parseInt(x['UI/UX'])) +
          isNumber(parseInt(x['QA'])) +
          isNumber(parseInt(x['BA/PM']));
        data.backend = data.backend + isNumber(parseInt(x.Backend));
        data.frontend = isWeb
          ? data.frontend + isNumber(parseInt(x.Frontend))
          : 0;
        data.android = isAndroid
          ? data.android + isNumber(parseInt(x.Android))
          : 0;
        data.iOS = isIOS ? data.iOS + isNumber(parseInt(x.iOS)) : 0;
        data.react_native = isHybrid
          ? data.react_native + isNumber(parseInt(x['React Native']))
          : 0;
        data.ux_ux = data.ux_ux + isNumber(parseInt(x['UI/UX']));
        data.q_a = data.q_a + isNumber(parseInt(x['QA']));
        data.ba_pa = data.ba_pa + isNumber(parseInt(x['BA/PM']));
      });
      return data;
    }
    return null;
  }, [estimateData, isAndroid, isHybrid, isIOS, isWeb]);

  const handleOpenFeatureModal = useCallback(() => {
    setIsModalFeature(true);
  }, []);

  const handleCloseFeatureModal = useCallback(() => {
    setIsModalFeature(false);
  }, []);

  const handleOpenGetEstimateModal = useCallback(() => {
    setIsModalEstimate(true);
  }, []);

  const handleChange = () => setChecked(prev => !prev);

  const handleSendEmail = useCallback(async () => {
    setIsSubmited(true);
    const summary = {
      'Feature Name': 'Total',
      Backend: total?.backend,
      Frontend: total?.frontend ? total.frontend : undefined,
      Android: total?.android ? total?.android : undefined,
      iOS: total?.iOS ? total.iOS : undefined,
      'React Native': total?.react_native ? total.react_native : undefined,
      'UI/UX': total?.ux_ux,
      QA: total?.q_a,
      'BA/PM': total?.ba_pa,
      'Total Hours': total?.totalHours,
      'Total Budget': `$${(rate * (total?.totalHours || 0)).toLocaleString(
        'en-US'
      )}`
    };

    const details = {
      stateHowBigAppLevel: stateHowBigAppLevel.map(item => ({
        title: item.title,
        selected: item.selected
      })),
      stateUILevel: stateUILevel.map(item => ({
        title: item.title,
        selected: item.selected
      })),
      stateQualityLevel: stateQualityLevel.map(item => ({
        title: item.title,
        selected: item.selected
      })),
      statePlatformsLevel: statePlatformsLevel.map(item => ({
        title: item.title,
        selected: item.selected
      })),
      AIQuestions: AIQuestions.map(question => ({
        question: question.title,
        answer: question.text
      }))
    };

    const totalEstimates = [...estimateData, summary];

    try {
      await sendEmailEstimate({
        userName,
        email,
        table: totalEstimates,
        details
      }).then(data => {
        if (data === false) {
          setIsBlur(true);
        } else {
          setIsBlur(false);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, [email, estimateData, userName, total, rate]);

  const handleCloseModal = () => {
    setIsSubmited(false);
    setIsModalEstimate(false);
  };

  const handleCloseGetEstimateModal = useCallback(() => {
    if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address.');
      return;
    }

    setEmailError('');
    handleSendEmail();
    setEmail('');
    setUserName('');
    setIsEmailValid(false);
  }, [handleSendEmail, email]);

  const handleAddFeatureModal = useCallback(() => {
    handleAddFeature(featureName, featureDescription, roleName);
    handleCloseFeatureModal();
    setFeatureName('');
    setFeatureDescription('');
    setRoleName('');
  }, [
    featureDescription,
    featureName,
    handleAddFeature,
    handleCloseFeatureModal,
    roleName
  ]);

  const countTable = useMemo(() => {
    let count = 4;
    if (isAndroid) {
      count = count + 1;
    }
    if (isIOS) {
      count = count + 1;
    }
    if (isHybrid) {
      count = count + 1;
    }
    if (isWeb) {
      count = count + 1;
    }
    return count;
  }, [isAndroid, isHybrid, isIOS, isWeb]);

  const roleQuestion = useMemo(() => {
    return AIQuestions?.[3];
  }, [AIQuestions]);

  return (
    <div className={`personal-estimate`}>
      <div
        className="personal-estimate-container"
        style={
          estimateData && estimateData.length ? { height: 'auto' } : undefined
        }
      >
        <div className="estimate-container">
          <BlockTitle index={7} title={title} />
          <div className="estimate-container-second">
            <div className="text">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_1131_5668)">
                  <path
                    d="M12 2C6.475 2 2 6.475 2 12C2 17.525 6.475 22 12 22C17.525 22 22 17.525 22 12C22 6.475 17.525 2 12 2ZM13 17H11V11H13V17ZM13 9H11V7H13V9Z"
                    fill="#8585FF"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1131_5668">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <p>
              To generate a project estimate, please complete all required
              fields and select options on each step.
            </p>
            <div className="margin-auto">
              <Image src={`/images/arrow`} className="icon" />
            </div>
          </div>
          <div>
            {/*<h3 className="desc">{desc}</h3>*/}
            {estimateData && estimateData.length ? (
              <div />
            ) : (
              <div
                onClick={isDisable ? handleOnScroll : handleGetEstimate}
                className={`estimate-button ${isDisable ? 'disable' : ''}`}
              >
                {isLoadingEstimate ? (
                  <span className="loader-estimate" />
                ) : (
                  <p className="estimate-button-text">{button}</p>
                )}
              </div>
            )}
          </div>
        </div>

        {estimateData && estimateData.length ? (
          <div>
            <div className="separate" />
            <div className="containerTable">
              <div
                className="tableHeader"
                style={{
                  gridTemplateColumns: `3fr 4fr repeat(${countTable}, 1fr)`
                }}
              >
                <div>
                  <p className="tableHeaderText">Feature Description</p>
                </div>
                <div />
                <div>
                  <p className="tableHeaderText center">Backend</p>
                </div>
                {isWeb ? (
                  <div>
                    <p className="tableHeaderText center">Frontend</p>
                  </div>
                ) : null}
                {isAndroid ? (
                  <div>
                    <p className="tableHeaderText center">Android</p>
                  </div>
                ) : null}
                {isIOS ? (
                  <div>
                    <p className="tableHeaderText center">iOS</p>
                  </div>
                ) : null}
                {isHybrid ? (
                  <div>
                    <p className="tableHeaderText center">React Native</p>
                  </div>
                ) : null}
                <div>
                  <p className="tableHeaderText center">UI/UX</p>
                </div>
                <div>
                  <p className="tableHeaderText center">Q/A</p>
                </div>
                <div>
                  <p className="tableHeaderText center">BA - PM</p>
                </div>
              </div>
              {estimateData.map((x, i) => {
                return (
                  <div
                    key={i + 'estimateTable'}
                    className={`table${
                      i % 2 !== 0 ? ' whiteRow' : ' purpulRow'
                    }`}
                    style={{
                      gridTemplateColumns: `3fr 4fr repeat(${countTable}, 1fr)`
                    }}
                  >
                    <div className="tableRow">
                      <p className="title">Feature Name</p>
                      <p className="text">{x['Feature Name']}</p>
                      <p className="title2">Acceptance Criteria</p>
                      <p className="text2">{x['Acceptance Criteria']}</p>
                    </div>
                    <div className="tableRow">
                      <p className="title">3rd Party Services</p>
                      <p className="text">{x['3rd Party Services']}</p>
                      <p className="title2">User Story</p>
                      <p className="text2">{x['User Story']}</p>
                    </div>
                    <div className="tableRowNumber">
                      <p className="mobileNumber">Backend</p>
                      <p className="number">{isNumber(x.Backend)}</p>
                    </div>
                    {isWeb ? (
                      <div className="tableRowNumber distance">
                        <p className="mobileNumber">Frontend</p>
                        <p className="number">
                          {isWeb ? isNumber(x.Frontend) : 0}
                        </p>
                      </div>
                    ) : null}
                    {isAndroid ? (
                      <div className="tableRowNumber distance">
                        <p className="mobileNumber">Android</p>
                        <p className="number">
                          {isAndroid ? isNumber(x.Android) : 0}
                        </p>
                      </div>
                    ) : null}
                    {isIOS ? (
                      <div className="tableRowNumber">
                        <p className="mobileNumber">iOS</p>
                        <p className="number">{isIOS ? isNumber(x.iOS) : 0}</p>
                      </div>
                    ) : null}
                    {isHybrid ? (
                      <div className="tableRowNumber distance">
                        <p className="mobileNumber">React Native</p>
                        <p className="number">
                          {isHybrid ? isNumber(x['React Native']) : 0}
                        </p>
                      </div>
                    ) : null}
                    <div className="tableRowNumber distance">
                      <p className="mobileNumber">UI/UX</p>
                      <p className="number">{isNumber(x['UI/UX'])}</p>
                    </div>
                    <div className="tableRowNumber">
                      <p className="mobileNumber">QA</p>
                      <p className="number">
                        {Math.floor(isNumber(x['QA']) || 0)}
                      </p>
                    </div>
                    <div className="tableRowNumber distance">
                      <p className="mobileNumber">BA/PM</p>
                      <p className="number">
                        {Math.floor(isNumber(x['BA/PM'])) || 0}
                      </p>
                    </div>
                    {!isFetching && (
                      <div
                        onClick={() => handleDeleteFeature(i)}
                        className="deleteContainer"
                      >
                        <Image
                          src={`/images/deleteFeature`}
                          className="deleteIcon"
                        />
                      </div>
                    )}
                  </div>
                );
              })}

              <div
                onClick={isFetching ? () => {} : handleOpenFeatureModal}
                className="plusFeature"
              >
                {isFetching ? (
                  <span className="loader-estimate" />
                ) : (
                  <>
                    <Image src={`/images/plus`} className="plusIcon" />
                    <p className="plusText">Add feature</p>
                  </>
                )}
              </div>
              <div
                className="budget-container"
                style={{
                  gridTemplateColumns: `3fr 4fr repeat(${countTable}, 1fr)`
                }}
              >
                <div className="budget-first-section">
                  <p className="budget-title">Total Budget</p>
                  <p className={`budget-text${isBlur ? '-bluer' : ''}`}>
                    {`$${(rate * total.totalHours).toLocaleString('en-US')}`}
                  </p>
                </div>
                <div className="budget-second-section">
                  <p className="budget-title">Total Hours</p>
                  <p className={`budget-text${isBlur ? '-bluer' : ''}`}>
                    {total.totalHours}
                  </p>
                </div>
                <div className="budget-first-little-section">
                  <p className="budget-text-mobile">Backend</p>
                  <p className={`budget-text${isBlur ? '-bluer' : ''}`}>
                    {total.backend}
                  </p>
                </div>
                {isWeb ? (
                  <div className="budget-center-little-section distance">
                    <p className="budget-text-mobile">Frontend</p>
                    <p className={`budget-text${isBlur ? '-bluer' : ''}`}>
                      {total.frontend}
                    </p>
                  </div>
                ) : null}
                {isAndroid ? (
                  <div className="budget-center-little-section distance">
                    <p className="budget-text-mobile">Android</p>
                    <p className={`budget-text${isBlur ? '-bluer' : ''}`}>
                      {total.android}
                    </p>
                  </div>
                ) : null}
                {isIOS ? (
                  <div className="budget-center-little-section">
                    <p className="budget-text-mobile">iOS</p>
                    <p className={`budget-text${isBlur ? '-bluer' : ''}`}>
                      {total.iOS}
                    </p>
                  </div>
                ) : null}
                {isHybrid ? (
                  <div className="budget-center-little-section distance">
                    <p className="budget-text-mobile">React Native</p>
                    <p className={`budget-text${isBlur ? '-bluer' : ''}`}>
                      {total.react_native}
                    </p>
                  </div>
                ) : null}
                <div className="budget-center-little-section distance">
                  <p className="budget-text-mobile">UI/UX</p>
                  <p className={`budget-text${isBlur ? '-bluer' : ''}`}>
                    {total.ux_ux}
                  </p>
                </div>
                <div className="budget-center-little-section mobile-bottom-left-radius">
                  <p className="budget-text-mobile">QA</p>
                  <p className={`budget-text${isBlur ? '-bluer' : ''}`}>
                    {total.q_a}
                  </p>
                </div>
                <div className="budget-last-little-section distance">
                  <p className="budget-text-mobile">BA/PM</p>
                  <p className={`budget-text${isBlur ? '-bluer' : ''}`}>
                    {total.ba_pa}
                  </p>
                </div>
              </div>

              <div className="get-estimate">
                {!isFetching ? (
                  <div
                    className="get-estimate-button"
                    onClick={handleOpenGetEstimateModal}
                  >
                    <p className="get-estimate-button-text">
                      Get your estimate
                    </p>
                  </div>
                ) : (
                  <div />
                )}
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <Modal
        open={isModalFeature}
        onClose={handleCloseFeatureModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modal-container">
          <div className="modal-feature-container">
            <form onSubmit={handleAddFeatureModal}>
              <div className="modal-feature-header">
                <p className="modal-feature-header-title">Add new feature</p>
                <Input
                  id="feature-name"
                  value={featureName}
                  onChange={event => handleSetText(event, setFeatureName)}
                  placeholder="Feature name"
                  required
                  className="modal-feature-header-input"
                />
                <Input
                  id="feature-description"
                  value={featureDescription}
                  onChange={event =>
                    handleSetText(event, setFeatureDescription)
                  }
                  placeholder="Feature description"
                  className="modal-feature-header-input"
                />
              </div>
              <div className="modal-feature-body">
                <p className="modal-feature-body-text">Roles</p>
                <MultiSelectQuestion
                  options={roleQuestion?.options || []}
                  onChangeText={setRoleName}
                  placeholder={roleQuestion?.placeholder}
                  customInputPlaceholder={roleQuestion?.customRolePlaceholder}
                  selectedMultipleItems={roleQuestion?.selectedMultipleOptions}
                />
                <button
                  type="submit"
                  className="modal-feature-body-add-button"
                  disabled={
                    !roleQuestion?.selectedMultipleOptions?.length ||
                    !featureName ||
                    !featureDescription
                  }
                >
                  <p className="modal-feature-body-add-text">Add feature</p>
                </button>
              </div>
            </form>

            <div
              onClick={handleCloseFeatureModal}
              className="modal-close-button"
            >
              <Image
                className="modal-close-button-image"
                src={'/images/close-modal'}
              />
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={isModalEstimate}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modal-container">
          <div className="modal-estimate-container">
            <div className="modal-estimate-header">
              {!isSubmited && (
                <>
                  <p className="modal-estimate-header-title">
                    Get your estimate
                  </p>
                  <p className="modal-estimate-header-desc">
                    Add your email and get your estimate
                  </p>
                  <Image
                    className="modal-estimate-header-image"
                    src={'/images/modal-icon'}
                    alt="modal icon"
                    width={181}
                    height={158}
                  />
                </>
              )}
              {isSubmited && (
                <>
                  <Box sx={{ mr: 5, mt: 5 }}>
                    <Image
                      src={'/images/modal-icon-done'}
                      alt="modal icon"
                      width={240}
                      height={272}
                    />
                  </Box>
                  <p className="modal-estimate-header-title width">
                    Your estimate is on its way to your inbox!
                  </p>
                </>
              )}
            </div>

            {!isSubmited && (
              <form
                className="modal-estimate-body"
                onSubmit={handleCloseGetEstimateModal}
              >
                <Input
                  className="modal-estimate-body-input"
                  value={userName}
                  onChange={event => handleSetText(event, setUserName)}
                  placeholder="Name"
                  required
                />
                <Input
                  type="email"
                  className="modal-estimate-body-input"
                  value={email}
                  onChange={handleEmailChange}
                  placeholder="emailexample@gmail.com"
                  required
                  error={!!emailError}
                />
                {emailError && <p className="error-text">{emailError}</p>}

                <Box sx={{ marginTop: 24, marginBottom: 24 }}>
                  <FormControlLabel
                    style={{
                      margin: 0
                    }}
                    label={
                      <Typography className="title-label" variant="body2">
                        I agree that my personal information will be processed
                        and stored by Geniusee.
                      </Typography>
                    }
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={handleChange}
                        color="primary"
                        style={{
                          padding: 0,
                          marginRight: '5px'
                        }}
                      />
                    }
                  />
                </Box>
                <button
                  type="submit"
                  className="modal-estimate-body-button mt-0"
                  disabled={!isEmailValid || !checked}
                >
                  <p className="modal-estimate-body-button-text">Confirm</p>
                </button>
              </form>
            )}

            {isSubmited && (
              <form
                className="modal-estimate-body bg"
                onSubmit={handleCloseModal}
              >
                <button
                  type="submit"
                  className="modal-estimate-body-button confirm"
                >
                  <p className="modal-estimate-body-button-text">Ok</p>
                </button>
              </form>
            )}

            <div onClick={handleCloseModal} className="modal-close-button">
              <img
                className="modal-close-button-image"
                src="/images/close-modal.png"
                alt="close icon"
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PersonalEstimate;
